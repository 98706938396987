// load font
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,300italic,400italic,600italic');

// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Import Bootstrap
// @import "bootstrap/dist/css/bootstrap.css";

// Temp fix for reactstrap
//@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// @import '~react-datetime/css/react-datetime.css';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

@import "spin";

// custom sidebar
@import "sidebarCutom";

// load style table
@import "tableCustom";

// custom style
@import "main";

// login style
@import "login";

// MObile response
@import "mobile";

// custom echarts
// @import "echartsCustom";
